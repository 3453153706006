<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="news-left pt-5 white-bg" style="float: unset;">
          <div class="left-top">
            <h3 class="news-title" style="color: #074d8c;" v-html="newsDetails.newsTitle"></h3>
            <div class="top-info" style="border-bottom: 1px dashed #b0b0b0;">
              <span style="margin-right: 40px">发布日期：{{(newsDetails.createTime||'').substring(0,10)}}</span>
              <span style="margin-right: 40px" v-if="newsDetails.newsFrom">来源：{{newsDetails.newsFrom}} </span>
              <span>浏览量：<span> {{newsDetails.viewCount}}次</span></span>

            </div>
          </div>
          <div class="detail-contanier">
            <div class="detail-box left-main" v-html="newsDetails.newsContent">
              <div class="text-center detail-btn">
                <button type="button" class="btn btn-info mr-2">打印本页</button>
                <button type="button" class="btn btn-secondary">关闭窗口</button>
              </div>
            </div>
          </div>
        </div>









      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "./index-header.vue"
  import Footer from "./index-footer.vue"
  import Navbar from "./navbar.vue"

  import { newsDetail,newsViewCount } from "@/api/web/web.js";
  export default {
    name: "index",
    data() {
      return {
        newsDetails: {}
      }

    },
    components: {
      Header,
      Navbar,
      Footer
    },
    methods: {
      getDetails() {
        newsDetail(this.$route.query.newsId).then(res => {
          if (res.status) {
            if (res.data.newsSort == 'url') {
              window.open(res.data.newsUrl, "_self");
            } else {
              res.data.newsTitle = res.data.newsTitle.replace(/\n/g,'<br>')
              this.newsDetails = res.data
            }
            // 更新浏览量
            newsViewCount(this.$route.query.newsId)
          }
        })
      },
    },
    mounted() {
      this.getDetails()
    }
  };
</script>

<style>
  @import '~@/assets/css/common.css';
  @import '~@/assets/css/index.css';
</style>
<style type="text/css" scoped>
  .detail-contanier p {
    text-indent: 2rem;
    margin-bottom: 0;
  }
  .detail-contanier {
    min-height: 350px;
  }

  /deep/ .left-main table {
    width: 90% !important;
    margin: 0 auto !important;
    border: 1px solid #ccc !important;
    border-collapse: collapse;
  }

  /deep/ .left-main table td,
  .left-main table th {
    text-align: center;
    padding: 5px 0;
    border: 1px solid #ccc !important;
  }

  /deep/ .left-main p {
    line-height: 32px;
    font-size: 16px;
    margin: 0;
  }

  /deep/ .left-main p img {
    display: initial;
    max-width: 85% !important;
    height: auto !important;
  }
  .left-main p>img {
    display: block;
  }
  .left-main {
    color: #000;
    /* border-bottom: 1px dashed #676767; */
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  .left-main p {
    text-indent: 24px;
    line-height: 30px;
  }
</style>
